<template>
  <transition>
    <div class="wrapper">
      <div class="social-space-chatrooms w-100">
        <div class="top-bar d-flex align-items-center">
          <div class="section-title mb-2">{{ sectionTitle }}</div>
        </div>
        <ChatroomCard
          class="mb-2"
          :openBlank="openBlank"
          v-for="room in chatrooms"
          v-show="!ischatLoading"
          :key="room.id"
          :member-count="get(counts, `${room.id}.member-count`) || 0"
          :status="get(counts, `${room.id}.status`) || false"
          :chatroom="room"
          :muted="get(mutedStatus, `${room.id}.is_muted`)"
          :unreadchat="get(chatUnreadCounts, `${room.id}.unread_count`) || 0"
          :lastmsg="get(counts, `${room.id}.last_msg_time`) || null"
        />
      </div>
      <span class="d-flex flex-column">
        <ion-skeleton-text v-if="ischatLoading" :animated="true" class="chat-loader ml-1" />
        <ion-skeleton-text v-if="ischatLoading" :animated="true" class="chat-loader ml-1" />
        <ion-skeleton-text v-if="ischatLoading" :animated="true" class="chat-loader ml-1" />
      </span>

      <div class="d-flex align-items-center mt-3">
        <back-to-top v-if="toTop" class="d-flex align-self-start" />
        <router-link v-if="seeAllLink" :to="seeAllLink" class="ml-auto mr-0 see-all-text" @click="goToChatrooms"
          ><b>See all</b></router-link
        >
      </div>
    </div>
  </transition>
</template>

<script lang="ts" setup>
import BackToTop from '@/shared/components/BackToTop.vue';
import ChatroomCard from '@/shared/components/storage/ChatroomCard.vue';
import { getchatUnreadCounts, getUnmuteAndMuteNotificationStatus } from '@/shared/actions/socialSpaceChatroom';

import { authStore } from '@/shared/pinia-store/auth';

const { isAuthenticated } = authStore();

const emits = defineEmits(['switchTab']);
const props = defineProps({
  sectionTitle: {
    type: String,
    required: true,
  },
  toTop: {
    type: Boolean,
    default: false,
  },
  seeAllLink: {
    type: Object,
  },
  chatrooms: {
    type: Object,
  },
  isSearch: {
    type: Boolean,
    default: false,
  },
  counts: {
    type: Object,
  },
  ischatLoading: {
    type: Boolean,
    default: false,
  },
  openBlank: {
    type: Boolean,
    default: false,
  },
});

const chatUnreadCounts = ref({});
const mutedStatus = ref({});
const chatrooms = toRef(props, 'chatrooms');
const isSearch = toRef(props, 'isSearch');
const openBlank = toRef(props, 'openBlank');
watch(chatrooms, () => {
  if (get(chatrooms.value, 'length') && isAuthenticated.value) {
    fetchchatUnreadCounts();
    fetchMutedAndUmuteStatus();
  }
});

const goToChatrooms = () => {
  if (isSearch.value) {
    emits('switchTab');
  }
};

const fetchchatUnreadCounts = async () => {
  chatUnreadCounts.value = {};
  const resp = await getchatUnreadCounts(map(chatrooms.value, 'id') as string[]);
  chatUnreadCounts.value = keyBy(resp, 'room');
};
const fetchMutedAndUmuteStatus = async () => {
  const resp = await getUnmuteAndMuteNotificationStatus(map(chatrooms.value, 'id') as string[]);
  mutedStatus.value = keyBy(resp, 'room');
};
</script>

<style lang="sass" scoped>
.chat-loader
  width: calc(100% - 0.5rem)
  height: 120px
  border-radius: 12px
.see-all-text
  color: #214163
  display: inline-block
.chatroom-btn
  --border-radius: 15px
  height: 22px
.text
  font-size: 12px
  margin-top: 0.22rem

.social-space-chatrooms
  overflow-x: auto
.section-title
  font-size: 24px
  font-weight: bold
.title
  font-size: 20px
  font-weight: bold
.description
  font-size: 14px
.icon
  font-size: 16px
  font-weight: bold
.chat-room-card
  overflow: unset

  @media(min-width: 420px)
    min-width: 100% !important
    max-width: 28% !important
  @media(max-width: 419px)
    min-width: 100% !important
    max-width: 50% !important
  .img
    width: 65px !important
    height: 65px !important
    border-radius: 35px
    border: solid gray 0.1px
    object-fit: cover

.chat-rooms
  overflow-x: auto
  overflow: unset
.chat-rooms::-webkit-scrollbar
  display: none
.chat-rooms
  -ms-overflow-style: none
  scrollbar-width: none
</style>
